// =================================================
// IMPORTS
// -------------------------------------------------
// React and MUI Components
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

// Contexts
import { useAuth } from "../contexts/auth";

import { Card, CardContent, Typography, Tabs, Tab } from "@mui/material";

// Icons
import { Done as IconDone } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

import { fetchSurveyById } from "../redux/reducers/surveys";

// Custom Components
import DashboardLineChart from "./dashboard-cbti/Dashboard_LineChart";
import SleepBarChart from "./dashboard-cbti/SleepBarChart";
import SurveyResults from "./dashboard-cbti/SurveyResults";

// =================================================
// COMPONENT
// -------------------------------------------------

const DashboardCBTIContent = React.memo(({ participantData }) => {
  const theme = useTheme();
  const { currentAuth } = useAuth();
  const dispatch = useDispatch();

  const surveyEntities = useSelector((state) => state.surveys.entities);

  // Use optional chaining to safely access participantData properties
  const cbtiTasks = participantData?.cbtiTasks || [];

  // Collect all required survey IDs
  const surveyIdsNeeded = cbtiTasks
    .map((task) => task.taskResponse?.surveyId)
    .filter(Boolean);

  // State for managing selected tab
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = useCallback(
    (event, newValue) => {
      setSelectedTab(newValue);
      const week = cbtiTasks[newValue].acronym.replace("CBCTI", "");
      const element = document.getElementById(`week-${week}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    [cbtiTasks],
  );

  // Fetch surveys when cbtiTasks change
  useEffect(() => {
    surveyIdsNeeded.forEach((surveyId) => {
      // Survyes are sometimes partially loaded, one field that is missing is aliasList
      if (!surveyEntities[surveyId].aliasList) {
        dispatch(
          fetchSurveyById({
            requestingUser: currentAuth,
            surveyId,
            upsert: true,
          }),
        );
      }
    });
  }, [surveyIdsNeeded, dispatch, currentAuth, surveyEntities]);

  // Check if all surveys are loaded
  const allSurveysLoaded = surveyIdsNeeded.every((id) => surveyEntities[id]);

  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------

  if (!allSurveysLoaded) {
    return <Typography>Loading surveys...</Typography>;
  }

  return (
    <div className="mb-2">
      <Card className="mb-2">
        <CardContent>
          <div className="row">
            <div className="col-6 p-0">
              <SleepBarChart data={participantData} />
            </div>
            <div className="col-6">
              <div className="row">
                {[
                  {
                    data: participantData?.sleepEfficiency ?? [],
                    title: "Sleep Efficiency (%)",
                  },
                  {
                    data: participantData?.totalSleepTime ?? [],
                    title: "Total sleep time (min)",
                  },
                  {
                    data: participantData?.sleepQuality ?? [],
                    title: "Sleep quality",
                  },
                  {
                    data: participantData?.timeTillSleep ?? [],
                    title: "Sleep onset time (min)",
                  },
                  {
                    data: participantData?.restedInMorning ?? [],
                    title: "Restedness in the morning",
                  },
                ].map((chart, index) => (
                  <div className="col-6 mb-2" key={index}>
                    <DashboardLineChart data={chart.data} title={chart.title} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="mb-2">
        <CardContent>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {cbtiTasks.map((task, index) => (
              <Tab
                key={index}
                label={`Module ${task.acronym.replace("CBCTI", "")}`}
                icon={
                  task.isCompleted && (
                    <IconDone
                      fontSize="small"
                      style={{ color: theme.palette.primary.main }}
                    />
                  )
                }
                iconPosition="end"
              />
            ))}
          </Tabs>
          <Typography variant="h6">Survey Responses</Typography>
          <SurveyResults
            cbtiTasks={cbtiTasks}
            surveyEntities={surveyEntities}
          />
        </CardContent>
      </Card>
    </div>
  );
});

// =================================================
// EXPORT COMPONENT
export default DashboardCBTIContent;
