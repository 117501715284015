// =================================================
// IMPORTS
// -------------------------------------------------
import React from "react";
import { Card, CardContent, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import PollIcon from "@mui/icons-material/Poll";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

// =================================================
// COMPONENT
// -------------------------------------------------

const DashboardCBTIOverview = ({
  setShowOverview = () => {}, // Default to no-op to avoid undefined issues
  therapists = [], // Default to empty array to avoid map errors
  resultsByUser = {}, // Default to empty object to avoid property access issues
  setSelectedParticipant = () => {}, // Default to no-op to avoid undefined issues
}) => {
  const theme = useTheme();

  // Get secondary color from CSS variables
  const secondaryColor =
    getComputedStyle(document.documentElement).getPropertyValue(
      "--color-secondary-a",
    ) || "#000"; // Default to black if color is not found

  // Define columns for the DataGrid
  const columns = [
    {
      field: "number",
      headerName: "NSR number",
      width: 150,
    },
    {
      field: "name",
      headerName: "Participant",
      width: 300,
    },
    // {
    //   field: "email",
    //   headerName: "Email",
    //   width: 50,
    //   renderCell: () => (
    //     <MailOutlineIcon style={{ color: theme.palette.text.disabled }} />
    //   ),
    // },
    {
      field: "cbtiProgress",
      headerName: "CBTI Progress",
      width: 200,
      renderCell: (params) => (
        <div>
          {(params?.value || []).map((completed, index) => (
            <PollIcon
              key={index}
              style={{
                color: completed
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
              }}
            />
          ))}
        </div>
      ),
    },
    {
      field: "sleepLogs",
      headerName: "Sleep Logs",
      width: 200,
      renderCell: (params) => (
        <div>
          {(params?.value || []).map((completed, index) => (
            <svg key={index} width="10" height="20" style={{ marginRight: 4 }}>
              <rect
                width="10"
                height="20"
                fill={completed ? secondaryColor : theme.palette.text.disabled}
              />
            </svg>
          ))}
        </div>
      ),
    },
    {
      field: "sleepEfficiency",
      headerName: "Sleep Efficiency",
      width: 150,
      renderCell: (params) => {
        const data = Object.entries(params?.value || {}).map(
          ([date, value]) => ({
            date: date,
            value: value,
          }),
        );
        return (
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <ResponsiveContainer width={100} height="100%">
              <AreaChart
                data={data}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={theme.palette.primary.main}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={theme.palette.primary.main}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke={theme.palette.primary.main}
                  fill="url(#colorValue)"
                />
              </AreaChart>
            </ResponsiveContainer>
            <span style={{ marginLeft: 8 }}>
              {(() => {
                const mostRecentEntry = Object.entries(
                  params?.value || {},
                ).sort((a, b) => new Date(b[0]) - new Date(a[0]))[0];
                return mostRecentEntry
                  ? `${Math.round(mostRecentEntry[1] * 100)}%`
                  : "-";
              })()}
            </span>
          </div>
        );
      },
    },
  ];

  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------

  return (
    <div className="mb-2">
      {(therapists || []).map((therapist) => (
        <Card className="mb-2" key={therapist?._id || Math.random()}>
          <CardContent>
            <Typography variant="h6">{therapist?.name || "Unknown"}</Typography>
            <div style={{ width: "100%" }}>
              <DataGrid
                autosizeOnMount
                onCellClick={(params) => {
                  setShowOverview(false);
                  setSelectedParticipant({ id: params?.row?.id });
                }}
                rows={
                  Array.isArray(therapist?.participants)
                    ? therapist.participants.map((participant) => {
                        const participantId = participant?.id;
                        const cbtiTasks =
                          resultsByUser?.[participantId]?.cbtiTasks || [];

                        const cbtiProgress = cbtiTasks
                          .sort((a, b) =>
                            (a?.acronym || "").localeCompare(b?.acronym || ""),
                          )
                          .map((task) => (task?.isCompleted ? 1 : 0));

                        const sleepLogTasks = (
                          resultsByUser?.[participantId]?.sleepLogTasks || []
                        )
                          .filter(
                            (task) =>
                              new Date(task?.dateAvailable) <= new Date(),
                          )
                          .sort((a, b) =>
                            (a?.dateAvailable || "").localeCompare(
                              b?.dateAvailable || "",
                            ),
                          )
                          .slice(-14);
                        const sleepLogs = sleepLogTasks.map((task) =>
                          task?.isCompleted ? 1 : 0,
                        );

                        return {
                          id: participantId,
                          number: participant?.userNumber || "-",
                          name: participant?.email || "No Email",
                          // email: participant?.email || "No Email",
                          cbtiProgress,
                          sleepLogs,
                          sleepEfficiency:
                            resultsByUser?.[participantId]?.sleepEfficiency ||
                            {},
                        };
                      })
                    : []
                }
                columns={columns}
                hideFooter
                autoHeight
              />
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

// =================================================
// EXPORT COMPONENT
export default DashboardCBTIOverview;
