// =================================================
// IMPORTS
// -------------------------------------------------
// React
import React, { useState, useEffect } from "react";

// MUI Components
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";

// Custom Components
import AppListItem from "../components/App_ListItem";

// =================================================
// COMPONENT
// -------------------------------------------------

const DashboardCBTIColumnContent = ({
  showOverview,
  setShowOverview,
  therapists,
  setSelectedParticipant,
}) => {
  // State to track expanded therapists
  const [expandedTherapists, setExpandedTherapists] = useState({});

  // Initialize all therapists as expanded when the component mounts
  useEffect(() => {
    const initialExpandedState = therapists.reduce((acc, therapist) => {
      acc[therapist._id] = true;
      return acc;
    }, {});
    setExpandedTherapists(initialExpandedState);
  }, [therapists]);

  // Handle therapist item click to expand/collapse participant list
  const handleTherapistClick = (therapistId) => {
    setExpandedTherapists((prevState) => ({
      ...prevState,
      [therapistId]: !prevState[therapistId],
    }));
  };

  // ===============================================
  // RENDER COMPONENT
  // -----------------------------------------------

  return (
    <List component="nav" disablePadding>
      <AppListItem
        key="overview"
        textPrimary="Overview"
        slideIn
        isSelected={showOverview}
        onClick={() => setShowOverview(true)}
      />

      {therapists.map((therapist) => (
        <React.Fragment key={therapist._id}>
          <AppListItem
            textPrimary={therapist.name}
            icon={<PersonIcon />}
            slideIn
            divider
            onClick={() => handleTherapistClick(therapist._id)}
            iconEnd={
              expandedTherapists[therapist._id] ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )
            }
          />
          <Collapse
            in={expandedTherapists[therapist._id]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {therapist.participants.map((participant) => (
                <AppListItem
                  key={participant.id}
                  textPrimary={participant.email}
                  slideIn
                  divider
                  onClick={() => {
                    setShowOverview(false);
                    setSelectedParticipant(participant);
                  }}
                />
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

// =================================================
// EXPORT COMPONENT
export default DashboardCBTIColumnContent;
