import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { use } from "i18next";

const DashboardLineChart = ({ data, title }) => {
  const theme = useTheme();

  const [chartData, setChartData] = useState([]);

  // Transform the data into the format required by Recharts
  useEffect(() => {
    const sortedData = Object.entries(data)
      .map(([date, value]) => ({ x: new Date(date), y: value }))
      .sort((a, b) => a.x - b.x);
    setChartData(sortedData);
  }, [data]);

  return (
    <Box
      height={150}
      sx={{
        padding: 0,
        mb: 1,
        boxShadow: 1,
        borderRadius: 1,
        backgroundColor: "background.paper",
      }}
    >
      <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
        {title}
      </Typography>
      <ResponsiveContainer width="100%" height="80%">
        <AreaChart
          data={chartData}
          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0.8}
              />
              <stop
                offset="95%"
                stopColor={theme.palette.primary.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="x"
            scale="time"
            type="number"
            tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
            domain={[
              (dataMin) => new Date(Date.now() - 8 * 7 * 24 * 60 * 60 * 1000),
              (dataMax) => new Date(),
            ]}
          />
          <YAxis />
          {/* Optional: Include grid lines */}
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          {/* Optional: Include tooltips */}
          {/* <Tooltip /> */}
          <Area
            type="monotone"
            dataKey="y"
            stroke={theme.palette.primary.main}
            fill="url(#colorValue)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default DashboardLineChart;
