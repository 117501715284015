import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { Box, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Scatter,
  ComposedChart,
  ReferenceArea,
} from "recharts";
import CustomTooltip from "./customTooltip";
import { Duration } from "luxon";

// Helper functions
const adjustTimeTo24HourFormat = (time) => {
  time = Math.round(time) % 24;
  return time < 0 ? time + 24 : time;
};

const convertDurationToHours = (durationString) => {
  return Duration.fromISO(durationString).as("hours");
};

const convertTimeToHours = (timeString) => {
  return Duration.fromISOTime(timeString).as("hours");
};

// Define colors
const colors = {
  timeBeforeSleep: "#9d6c17c9",
  timeAwakeDuringSleep: "#bda270c9",
  timeAsleep: "#4c50af",
  timeAfterSleep: "#9d6c17c9",
};

const SleepBarChart = React.memo(({ data }) => {
  const [scrollPosition, setScrollPosition] = useState({ top: 0, height: 0 });
  const scrollDemoRef = useRef(null);
  const daysInView = 14;

  const chartData = useMemo(() => {
    if (!data || !data.sleepLogTasks) return [];

    const now = new Date();

    return data.sleepLogTasks
      .map((entry) => {
        const date = entry.taskResponse?.dateCompleted
          ? new Date(entry.taskResponse.dateCompleted)
          : new Date(entry.dateAvailable);

        return {
          date,
          dateValue: Math.floor(date.getTime() / 86400000) * 86400000,
          completed:
            entry.taskResponse && entry.taskResponse?.data.CSD2 !== null,
          offsetTime: convertTimeToHours(
            entry.taskResponse?.data.CSD2 || "0:0",
          ),
          timeBeforeSleep: convertDurationToHours(
            entry.taskResponse?.data.CSD3 || "PT0H",
          ),
          timeAwakeDuringSleep: convertDurationToHours(
            entry.taskResponse?.data.CSD5 || "PT0H",
          ),
          timeAsleep: entry.taskResponse?.data.CSD_TOTAL_SLEEP_TIME / 60 || 0,
          timeAfterSleep: convertDurationToHours(
            entry.taskResponse?.data.CSD7 || "PT0H",
          ),
          totalTime: convertDurationToHours(
            entry.taskResponse?.data.CSD_ATTEMPT_SLEEP_WINDOW || "PT0H",
          ),
          endTime:
            convertTimeToHours(entry.taskResponse?.data.CSD2 || "0:0") +
            convertDurationToHours(
              entry.taskResponse?.data.CSD_ATTEMPT_SLEEP_WINDOW || "PT0H",
            ),
          comment: entry.taskResponse?.data.CSD12 || "",
          marker: 2.5, // For the overview chart
          area: 2,
        };
      })
      .filter((entry) => entry.date <= now);
  }, [data]);

  const totalHeight = chartData.length * 30;

  const handleScroll = useCallback(() => {
    if (scrollDemoRef.current) {
      setScrollPosition({
        top: scrollDemoRef.current.scrollTop,
        height: scrollDemoRef.current.clientHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (scrollDemoRef.current) {
      scrollDemoRef.current.scrollTop = scrollDemoRef.current.scrollHeight;
    }
  }, [chartData]);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h6" sx={{ textAlign: "center", mb: 2 }}>
        Sleep Schedule
      </Typography>

      {chartData.length > 0 && (
        <>
          <ResponsiveContainer height={50}>
            <ComposedChart data={chartData}>
              <XAxis
                type="number"
                dataKey="dateValue"
                domain={[
                  (dataMin) => dataMin - 43200000,
                  (dataMax) => dataMax + 43200000,
                ]}
                scale="time"
                ticks={chartData
                  .filter((entry) => entry.date.getDay() === 1)
                  .map((entry) => entry.dateValue)
                  .sort((a, b) => a - b)}
                tickFormatter={(tick) => {
                  const date = new Date(tick);
                  return date.getDay() === 1 // Monday
                    ? date.toLocaleDateString("nl-NL", {
                        month: "numeric",
                        day: "numeric",
                      })
                    : " ";
                }}
              />
              <YAxis dataKey="marker" domain={[0, 5]} hide />
              <XAxis
                xAxisId="scrollRef"
                type="number"
                domain={[0, totalHeight]}
                hide
                allowDataOverflow
              />
              <ReferenceArea
                xAxisId="scrollRef"
                x1={scrollPosition.top}
                x2={scrollPosition.top + scrollPosition.height}
                y1={0}
                y2={5}
                stroke={colors.timeAwakeDuringSleep}
                fill={colors.timeAwakeDuringSleep}
                strokeOpacity={0.3}
              />
              <Scatter
                key={`scatter-incomplete`}
                data={chartData.filter((entry) => !entry.completed)}
                fill="#8884d811"
                stroke="#8884d8"
                onClick={(e) => {
                  if (scrollDemoRef.current) {
                    scrollDemoRef.current.scrollTo({
                      top:
                        (totalHeight * e.cx) / e.xAxis.width -
                        scrollDemoRef.current.clientHeight / 2,
                    });
                  }
                }}
              />

              {chartData.some((entry) => entry.completed === true) && (
                <Scatter
                  key={`scatter-complete`}
                  data={chartData.filter((entry) => entry.completed === true)}
                  fill="#8884d8"
                  onClick={(e) => {
                    if (scrollDemoRef.current) {
                      scrollDemoRef.current.scrollTo({
                        top:
                          (totalHeight * e.cx) / e.xAxis.width -
                          scrollDemoRef.current.clientHeight / 2,
                      });
                    }
                  }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          <div
            ref={scrollDemoRef}
            onScroll={handleScroll}
            style={{
              overflowY: "auto",
              height: "400px",
            }}
          >
            <ResponsiveContainer width="100%" height={totalHeight}>
              <BarChart
                data={chartData}
                layout="vertical"
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                barSize={10}
              >
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                {chartData.map((entry, index) =>
                  entry.date.getDay() === 1 ? (
                    <ReferenceLine
                      key={`refline-${entry.dateValue}-${index}-${entry.date.getTime()}-${Math.random()}`}
                      y={entry.dateValue}
                      stroke="#ccc"
                    />
                  ) : null,
                )}
                <XAxis
                  type="number"
                  domain={[(dataMin) => 19, (dataMax) => Math.max(dataMax, 35)]} // the api requires this particular function call syntax
                  ticks={[21, 24, 27, 30, 33]}
                  tickFormatter={(time) => adjustTimeTo24HourFormat(time)}
                />
                <YAxis
                  type="number"
                  dataKey="dateValue"
                  domain={[
                    (dataMin) => dataMin - 43200000,
                    (dataMax) => dataMax + 43200000,
                  ]}
                  scale="time"
                  ticks={chartData
                    .map((entry) => entry.dateValue)
                    .sort((a, b) => a - b)}
                  tickFormatter={(tick) => {
                    const date = new Date(tick);
                    return date.getDay() === 1 // Monday
                      ? date.toLocaleDateString("nl-NL", {
                          month: "numeric",
                          day: "numeric",
                        })
                      : date.toLocaleDateString("en-US", {
                          weekday: "short",
                        });
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="offsetTime"
                  stackId="sleep"
                  fill="black"
                  fillOpacity={0}
                />
                <Bar
                  dataKey="timeBeforeSleep"
                  stackId="sleep"
                  fill={colors.timeBeforeSleep}
                  radius={[10, 0, 0, 10]}
                />
                <Bar
                  dataKey="timeAwakeDuringSleep"
                  stackId="sleep"
                  fill={colors.timeAwakeDuringSleep}
                />
                <Bar
                  dataKey="timeAsleep"
                  stackId="sleep"
                  fill={colors.timeAsleep}
                />
                <Bar
                  dataKey="timeAfterSleep"
                  stackId="sleep"
                  fill={colors.timeAfterSleep}
                  radius={[0, 10, 10, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </Box>
  );
});

export default SleepBarChart;
