// Custom Tooltip Component
const CustomTooltip = ({ active, payload }) => {
  // Helper function to format durations
  const formatDuration = (time) => {
    const hours = Math.floor(time);
    const minutes = Math.round((time % 1) * 60);
    if (hours >= 1) {
      return `${hours} hour${hours > 1 ? "s" : ""} and ${minutes} minutes`;
    } else {
      return `${minutes} minutes`;
    }
  };

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    if (!data || data.offsetTime === undefined || isNaN(data.offsetTime)) {
      return null;
    }

    // Bedtime and wake-up time calculations
    const bedTime = data.offsetTime;
    const wakeUpTime = (data.offsetTime + data.totalTime) % 24;

    // Format bed time and wake-up time
    const bedTimeFormatted = `${Math.floor(bedTime)
      .toString()
      .padStart(2, "0")}:${Math.round((bedTime % 1) * 60)
      .toString()
      .padStart(2, "0")}u`;
    const wakeUpTimeFormatted = `${Math.floor(wakeUpTime)
      .toString()
      .padStart(2, "0")}:${Math.round((wakeUpTime % 1) * 60)
      .toString()
      .padStart(2, "0")}u`;

    // Format durations
    const timeBeforeSleepFormatted = formatDuration(data.timeBeforeSleep);
    const timeAwakeDuringSleepFormatted = formatDuration(
      data.timeAwakeDuringSleep,
    );
    const totalSleepTimeFormatted = formatDuration(data.timeAsleep);
    const comment = data.comment || "-";

    // Date formatting
    const bedDate = data.date;
    const wakeDate = new Date(data.date);
    if (bedTime + data.totalTime >= 24) {
      wakeDate.setDate(wakeDate.getDate() + 1);
    }

    const dateStartFormatted = bedDate.toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });
    const dateEndFormatted = wakeDate.toLocaleDateString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
    });

    // Define colors
    const colors = {
      timeBeforeSleep: "#ff8a80",
      timeAwakeDuringSleep: "#ffcc80",
      timeAsleep: "#80d8ff",
      timeAfterSleep: "#b388ff",
    };

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: 10,
          border: "1px solid #ccc",
        }}
      >
        <p>
          <strong>Date:</strong> {dateStartFormatted} to {dateEndFormatted}
        </p>
        <p>
          <strong>Time in bed - out of bed:</strong> {bedTimeFormatted} -{" "}
          {wakeUpTimeFormatted}
        </p>
        <p>
          <strong style={{ color: colors.timeBeforeSleep }}>
            Time awake before sleep:
          </strong>{" "}
          {timeBeforeSleepFormatted}
        </p>
        <p>
          <strong style={{ color: colors.timeAwakeDuringSleep }}>
            Time awake during sleep:
          </strong>{" "}
          {timeAwakeDuringSleepFormatted}
        </p>
        <p>
          <strong style={{ color: colors.timeAsleep }}>
            Total sleep time:
          </strong>{" "}
          {totalSleepTimeFormatted}
        </p>
        <p>
          <strong>Comment:</strong> {comment}
        </p>
      </div>
    );
  }

  return null;
};
export default CustomTooltip;
