import React, { useState, useEffect } from "react";
import { Box, Fab, Paper, IconButton, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "../../contexts/auth";
import { useSocket } from "../../contexts/socket";
import MessagesContent from "../Messages_Content";
import {
  fetchConversationList,
  conversationsSelectors,
} from "../../redux/reducers/conversations";
import {
  fetchMessageListByRecipient,
  selectMessageListByConversationId,
  patchCurrentMessage,
} from "../../redux/reducers/messages";

const FloatingChatButton = ({ consumerId }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentAuth } = useAuth();
  const { socket } = useSocket();
  const currentUser = useSelector((state) => state.user.currentUser);
  const consumer = useSelector((state) => state.consumers.entities[consumerId]);

  const conversationsStatus = useSelector(
    (state) => state.conversations.status,
  );
  const conversationList = useSelector((state) =>
    conversationsSelectors.selectAll(state),
  );

  const [currentConversation, setCurrentConversation] = useState(null);
  const messageList = useSelector((state) =>
    selectMessageListByConversationId(
      state,
      currentConversation ? currentConversation._id : null,
    ),
  );
  const recipientList = useSelector((state) => state.messages.recipientList);

  const handleSetConversationMessageListRead = async () => {
    let newRecipient, recipient;
    for (const msg of messageList) {
      recipient = recipientList.find(
        (r) => r.messageId === msg._id && r.userId === currentUser._id,
      );
      if (recipient && !recipient.isRead) {
        newRecipient = { ...recipient, isRead: true };
        await dispatch(
          patchCurrentMessage({
            socket,
            requestingUser: currentAuth,
            body: {
              data: newRecipient,
              meta: {
                setIsRead: true,
                userIdList: currentConversation.userIdList,
              },
            },
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (conversationsStatus === "idle" && currentUser) {
      dispatch(
        fetchConversationList({
          requestingUser: currentAuth,
          userId: currentUser._id,
        }),
      );
    }
  }, [conversationsStatus, dispatch, currentAuth, currentUser]);

  useEffect(() => {
    if (conversationList.length > 0) {
      const conversationsWithConsumer = conversationList.filter(
        (conv) =>
          conv.userIdList.includes(consumerId) &&
          conv.userIdList.includes(currentUser._id),
      );
      if (conversationsWithConsumer.length > 0) {
        const mostRecentConversation = conversationsWithConsumer.reduce(
          (latest, convo) =>
            new Date(convo.updatedAt) > new Date(latest.updatedAt)
              ? convo
              : latest,
        );
        setCurrentConversation(mostRecentConversation);
      }
    }
  }, [conversationList, consumerId, currentUser]);

  useEffect(() => {
    if (currentConversation) {
      dispatch(
        fetchMessageListByRecipient({
          requestingUser: currentAuth,
          recipientId: currentUser._id,
        }),
      );
    }
  }, [currentConversation, dispatch, currentAuth, currentUser]);

  const handleButtonClick = () => {
    setIsChatOpen(true);
    if (currentConversation) {
      dispatch(
        fetchMessageListByRecipient({
          requestingUser: currentAuth,
          recipientId: currentUser._id,
        }),
      );
    }
  };

  const handleCloseChat = () => {
    setIsChatOpen(false);
  };

  return (
    <>
      {/* Floating Action Button */}
      {!isChatOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 1000,
          }}
        >
          <Fab color="primary" aria-label="chat" onClick={handleButtonClick}>
            <MailIcon />
          </Fab>
        </Box>
      )}

      {/* Chat Window */}
      {isChatOpen && (
        <Box
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            width: 600,
            height: 600,
            zIndex: 1000,
          }}
        >
          <Paper
            elevation={3}
            sx={{ width: "100%", height: "100%", position: "relative" }}
          >
            {/* Title Bar */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 2,
                borderBottom: "1px solid #ccc",
              }}
            >
              <Box>
                <Typography variant="h6">
                  {consumer ? consumer.email : "Loading..."}
                  {consumer ? ` - NSR: ${consumer.userNumber}` : ""}
                </Typography>
              </Box>
              {/* Close Button */}
              <IconButton onClick={handleCloseChat}>
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Chat Content */}
            <Box
              sx={{
                height: "calc(100% - 64px)", // Adjust height to account for title bar
                overflowY: "auto",
              }}
            >
              {currentConversation ? (
                <MessagesContent
                  currentConversation={currentConversation}
                  handleSetConversationMessageListRead={
                    handleSetConversationMessageListRead
                  }
                />
              ) : (
                <Box sx={{ padding: 2, marginTop: 4 }}>
                  <p>
                    No conversations yet. Go to "Messages" and start a new
                    conversation first.
                  </p>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default FloatingChatButton;
